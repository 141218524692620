import React from 'react'
import Img from 'gatsby-image'

import ReactMarkdown from 'react-markdown'

const TeamMember = ({ data }) => {
  return (
    <article className='team-member'>
      <div className='mb-25'>
        {data.image && (
          <Img
            position='relative'
            className='team-member__image'
            alt={data.title}
            fluid={data.image.childImageSharp.fluid}
          />
        )}
      </div>
      <div className='team-member__content'>
        <h4 className='text-h4 lg:text-h3'>{data.title}</h4>
        <ReactMarkdown
          className='team-member__description'
          source={data.description}
        />
      </div>
    </article>
  )
}

export default TeamMember
