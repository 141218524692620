import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import SEO from '@/components/SEO/Seo'

import Layout from '@/components/Layout'
import Hero from '@/components/Hero/Hero'
import TeamMember from '@/components/TeamMember/TeamMember'

const TeamPageTemplate = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { edges: teamMembers } = data.people

  return (
    <>
      <Hero
        heading={frontmatter.heading}
        fluid={frontmatter.background.childImageSharp.fluid}
      />
      <div className='flex items-center justify-center'>
        <div className='container'>
          <div className='team-feed'>
            {frontmatter.team.map((member, i) => {
              let person = teamMembers.find(({ node: person }) => {
                return member === person.frontmatter.title
              })
              if (person) {
                return (
                  <TeamMember
                    key={`${i}${person.id}`}
                    data={person.node.frontmatter}
                  />
                )
              } else {
                return ''
              }
            })}
          </div>
        </div>
      </div>
    </>
  )
}

const TeamPage = ({ data }) => {
  return (
    <Layout hideNav={true}>
      <SEO data={data.markdownRemark.frontmatter.seo} />
      <TeamPageTemplate data={data} />
    </Layout>
  )
}

TeamPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export const pageQuery = graphql`
  query TeamPageTemplate {
    markdownRemark(frontmatter: { template: { eq: "team" } }) {
      frontmatter {
        title
        heading
        team
        background {
          childImageSharp {
            fluid(quality: 45, maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        ...SEO
      }
    }
    people: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "team-member" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 630, quality: 45) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            description
          }
        }
      }
    }
  }
`

export default TeamPage
